:root{
    --bg-color: rgba(153,202,254,1);
    

}


:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgba(8,36,66,1) 0%, rgba(8,36,66,1) 47%, rgba(4,24,45,1) 100%);
    --light-blue-color: #d0e1f9;
    --dark-blue-color: #2a4d69;
    --dark-text-color: #071627;
}



.main-area { 
    background-color: transparent; 
    overflow: hidden; 
    width: 100%; 
    overflow-x: hidden; 
    overflow-y: hidden;
} 

.center_xy { 
    align-items: center; 
    display: flex; 
    flex-direction: column;
    background: var(--main-bg);
    padding: 2rem 0;
} 

.progress { 
    height: 0.5rem; 
    line-height: 0; 
    font-size: 0.75rem; 
    background: var(--main-bg);
    border-radius: .25rem; 
    border-top-left-radius: 0.25rem; 
    border-top-right-radius: 0.25rem; 
    border-bottom-right-radius: 0.25rem; 
    border-bottom-left-radius: 0.25rem;
    display: flex; 
    overflow: hidden; 
    overflow-x: hidden; 
    overflow-y: hidden;
} 

.progress_bar { 
    display: flex; 
    overflow: hidden; 
    overflow-x: hidden; 
    overflow-y: hidden;
    flex-direction: column; 
    justify-content: center; 
    color: rgb(255, 255, 255); 
    text-align: center; 
    white-space: nowrap; 
    background-color: rgb(0, 123, 255); 
    transition: width .6s ease; 
    transition-duration: 0.6s; 
    transition-timing-function: ease; 
    transition-delay: 0s; 
    transition-property: width;
} 

.progress_bar_title span{
    font-size: 1.2rem !important;
    color: red !important;
}

.progress_bar_striped { 
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 0px, transparent 50%, rgba(255, 255, 255, 0.15) 0px, rgba(255, 255, 255, 0.15) 75%, transparent 0px, transparent); 
    background-size: 1rem 1rem;
}

.bg_success { 
    background-color: rgb(40, 167, 69) !important;
} 

.refer_footer {
    align-items: center; 
    height: 73px; 
    z-index: 3; 
} 

.font_11 {
    color: #2c2c2c;
    font-size: 1.1em;
    font-weight: 900;
}

.text_bold {
    color: #2c2c2c;
    font-size: 1em;
    font-weight: 800;
}
.font_9{
    color: #2c2c2c !important;
    font-weight: 700 !important;
    font-size: 0.9em  !important;

}
.font_8{
    font-weight: 500 !important;
    font-size: 0.8em  !important;

}
.c_green{
    color: #0db25b !important;
}