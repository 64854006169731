

:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgba(8,36,66,1) 0%, rgba(8,36,66,1) 47%, rgba(4,24,45,1) 100%);
    --light-blue-color: #d0e1f9;
    --dark-blue-color: #2a4d69;
    --dark-text-color: #071627;
}

#hambergar:hover{
    transform: scale(1.2);
}
.w3-button{
    /* background-color:blueviolet; */
    color:white;
    position:fixed;
    margin: auto;
    right: 0;
    top:20px;
    /* right:/20px; */
    padding:0.25rem;
    transition:transform 0.3s ease-in-out;
    /* background: linear-gradient(to right, #ff416c, #ff4b2b); */
}
.w3-button.active{
    transform: translateX(-100px);
}

.w3-sidebar{
    width:100%;  
    max-width:480px;
    height: 100%;
    background: var(--main-bg);
    color: black !important;
    position:fixed;
    top:0;
    left:-500px;
    height:100vh;
    transition:all 0.5s ;
    z-index: 99999999 !important;
    text-align: center;
    
}
.w3-sidebar-text .w3-bar-item div{
    padding-left: 1rem;
    text-align: center;
}
.w3-container{
    padding:0px;
    top: 0 !important;
    /* margin: auto; */
    background: #fff !important;
    
}
.w3-teal{
    background: white !important;

}
.w3-bar-block .w3-bar-item {
    background: var(--main-bg);
    
    padding: 1.2rem 4rem;
    /* height: 74px; */
    position: relative;
    width: 100%;
    align-items: center;
    display: flex;
    color: #ffffff !important;
    text-align: center;

}

/*.w3-bar-block .w3-bar-item:hover{*/
/*    background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118));*/
/*    color: black !important;*/
/*}*/


.arrow{
    position: absolute;
    right:25px;
}
.arrow>img {
    height: 11px;
    width: 6px;
    max-width: 100%;
}

.icon{
    overflow: hidden;
    position: relative;
}
.icon>img{
    /* height:28px; */
    width: 28px;
    /* max-width: 100%; */
}
#sidebarOverlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    z-index: 9999999;
    display: none;
    transition: all .5s ease-in-out 0s;
}

.top_nav_btn{
    border: none;
    outline: none;
    background-color: transparent;
    display: block;
    
}

.top_nav_btn i{
    font-size: 1.5rem;
}

.login_button{
    background: var(--light-blue-color);
    padding: 0.5rem 2rem;
    color: var(--dark-text-color);
    font-weight: bold; 
    border-radius: 0.3rem;
}

.login_button a:hover{
    color: #ffffff !important;
}

.login_button a::after,
.login_button a::before{
    color: #ffffff !important;
}

.login_button a:active{
    color: #ffffff !important;
}

.close_nav{
    position: relative;
    width: 100%;
    /* border:1px solid white; */
    min-height: 60px;
}
.close_nav i{
    color: rgb(255, 255, 255) !important;
    position: absolute;
    top: 2rem;
    right: 3rem;
    font-size: 2rem !important;
    cursor: pointer;
}