
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgba(8,36,66,1) 0%, rgba(8,36,66,1) 47%, rgba(4,24,45,1) 100%);
    --light-blue-color: #d0e1f9;
    --dark-blue-color: #2a4d69;
    --dark-text-color: #071627;
}

.games_section { 
    /* background-color: white !important; */
    /* background: #fff;  */
    background: var(--main-bg) !important;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #ffffff !important; 
    border-radius: 0; 
    position: relative; 
    width: 100%; 
    padding: 1rem;
    
    background-image: initial; 
    background-position-x: initial; 
    background-position-y: initial; 
    background-size: initial;
    background-attachment: initial; 
    background-origin: initial; 
    background-clip: initial; 
    /* background-color: rgb(255, 255, 255);  */
    border-radius: 0.3rem;
} 

.MuiFormControl_root { 
    border: 0; 
    margin: 0; 
    display: inline-flex; 
    padding: 0; 
    position: relative; 
    min-width: 0px; 
    flex-direction: column; 
    vertical-align: top; 
    border-top-width: 0px; 
    border-right-width: 0px; 
    border-bottom-width: 0px; 
    border-left-width: 0px; 
    border-top-style: initial; 
    border-right-style: initial; 
    border-bottom-style: initial; 
    border-left-style: initial; 
    border-top-color: initial; 
    border-right-color: initial; 
    border-bottom-color: initial; 
    border-left-color: initial; 
    border-image-source: initial; 
    border-image-slice: initial; 
    border-image-width: initial; 
    border-image-outset: initial; 
    border-image-repeat: initial; 
    margin-top: 0px; 
    margin-right: 0px; 
    margin-bottom: 0px; 
    margin-left: 0px; 
    padding-top: 0px; 
    padding-right: 0px; 
    padding-bottom: 0px; 
    padding-left: 0px;
} 
.gameCard_container { 
    margin-top: 20px; 
    justify-content: center; 
    position: relative; 
    width: 46.8%;
} 

.games_window { 
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-between;
} 
.add_fund_box { 
    background: var(--main-bg) !important;
    color: #ffffff !important;
    border: 1px solid #e0e0e0; 
    border-radius: 5px; 
    padding: 30px 0 15px 15px; 
    position: relative; 
    width: 100%; 
    background-image: initial; 
    background-position-x: initial; 
    background-position-y: initial; 
    background-size: initial; 
    background-attachment: initial; 
    background-origin: initial; 
    background-clip: initial; 
    border-top-width: 1px; 
    border-right-width: 1px; 
    border-bottom-width: 1px; 
    border-left-width: 1px; 
    border-top-style: solid; 
    border-right-style: solid; 
    border-bottom-style: solid; 
    border-left-style: solid; 
    padding-top: 30px; 
    padding-right: 0px; 
    padding-bottom: 15px; 
    padding-left: 15px;
}
.collapseCard_title { 
    color: rgb(255, 255, 255); 
    font-family: 'Poppins', sans-serif;
    font-size: 0.7em; 
    font-weight: 700; 
    text-transform: uppercase;
} 
.refer_footer { 
    align-items: center; 
    background: var(--main-bg);
    border-top: 1px solid #e0e0e0; 
    bottom: 0px; 
    display: flex; 
    height: 60px; 
    left: 0px; 
    /* max-width: 480px; 
    padding: 0 30px;  */
    width: 100%; 
    z-index: 3; 
    border-top-width: 1px; 
    border-top-style: solid; 
    border-top-color: rgb(224, 224, 224); 
    padding-top: 0px; 
    padding-right: 30px; 
    padding-bottom: 0px; 
    padding-left: 30px;
    border-radius: 0.3rem;
    margin-top: 2rem;
} 
.refer_button { 
    border: none; 
    border-radius: 5px; 
    font-size: 1em; 
    font-weight: 700; 
    height: 48px; 
    padding: 0 22px; 
    /* color: rgb(255, 255, 255);  */
    text-transform: uppercase; 
    border-top-width: initial; 
    border-right-width: initial; 
    border-bottom-width: initial; 
    border-left-width: initial; 
    border-top-style: none; 
    border-right-style: none; 
    border-bottom-style: none; 
    border-left-style: none; 
    border-top-color: initial; 
    border-right-color: initial; 
    border-bottom-color: initial; 
    border-left-color: initial; 
    border-image-source: initial; 
    border-image-slice: initial; 
    border-image-width: initial; 
    border-image-outset: initial; 
    border-image-repeat: initial; 
    border-top-left-radius: 5px; 
    border-top-right-radius: 5px; 
    border-bottom-right-radius: 5px; 
    border-bottom-left-radius: 5px; 
    padding-top: 0px; 
    padding-right: 22px; 
    padding-bottom: 0px; 
    padding-left: 22px;
} 

.cxy { 
    align-items: center; 
    display: flex; 
    justify-content: center;
} 

.bg_secondary { 
    background-color: rgb(108, 117, 125) !important;
} 
/* .btn:hover{
    background-color: black !important;

} */
.block{
    display: block;
    border: none;
    font-size: 16px;
    text-align: center;
    background: transparent;
    font-size: 1.25rem !important;
    color: #ffffff !important;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    width: 100%;
}

.upi_container{
    border:1px solid black;
}

