:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgba(8,36,66,1) 0%, rgba(8,36,66,1) 47%, rgba(4,24,45,1) 100%);
    --light-blue-color: #d0e1f9;
    --dark-blue-color: #2a4d69;
    --dark-text-color: #071627;
    --main-bg-right: linear-gradient(90deg, rgba(4,24,45,1) 0%, rgba(8,36,66,1) 47%, rgba(8,36,66,1) 100%);
}



.leftContainer{
    /* min-height: 100%; */
    position: relative;
}
.headerContainer, .leftContainer {
    background-color: var(--light-blue-color) !important;
    max-width: 480px;
    width: 100%;
   
}
.rightContainer {
    /* background: url(../../../public/Images/global-bg-rays.svg); */
  
    background-size: cover;
    overflow: hidden;
    right: 0;
    z-index: 4;
    background: var(--main-bg-right);


}
.rightContainer {
    bottom: 0;
    left: 480px;
    position: fixed;
    top: 0;
    
}
#root{
    height: 100vh;
}


.best_offer{
    position: absolute;
    bottom: 2rem;
}

:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgb(66, 18, 8) 0%, rgb(66, 29, 8) 47%, rgb(66, 18, 8) 100%) !important;
    --light-blue-color:linear-gradient(90deg, rgb(66, 18, 8) 0%, rgb(66, 29, 8) 47%, rgb(66, 18, 8) 100%) !important;
   --dark-blue-color: #56221b;
    --dark-text-color: #071627;
}


.main-area {
	background: #d0e1f9;
	background: linear-gradient(90deg,#2b0b04,#421d08 47%,#421a08);
	padding-top: 1rem;
	padding-inline: 1rem;
	padding-bottom: 10rem;
}


.game_card_section {
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 2rem;
	justify-content: center;
	margin: 1rem 0;
	/* padding: 9px 22px; */
	width: 100%;
}


.gameCard-image img {
	border-radius: 1rem;
	box-shadow: 0 10px 10px -10px rgb(250, 222, 114);
	border: 2px solid #ffdb4f;
	/* max-width: 150px; */
}

.Nav_headerContainer__4HOe7 {
	background: #421a08 !important;
	border-bottom: 2px solid #ffdb4f;
}


.login_button {
	background: #d0e1f9;
	background: #ffdb4f;
	color: #421708;
	font-weight: 500;
  padding: .5rem 1.2rem;
}

.rightContainer {
	background: linear-gradient(90deg,#04182d,#082442 47%,#082442);
	background-size: cover;
	background: linear-gradient(90deg,#2d0404,#6b2b0d 47%,#420808);
	bottom: 0;
	left: 480px;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 4;
}

.rcBanner-img-container {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 35%;
    width: 300px;
}

.Nav_headerContainer__4HOe7 .row {
	width: 100%;
}