@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgba(8,36,66,1) 0%, rgba(8,36,66,1) 47%, rgba(4,24,45,1) 100%);
    --light-blue-color: #d0e1f9;
    --dark-blue-color: #2a4d69;
    --dark-text-color: #071627;
    --white-text-color: #ffffff;
}

.mainArea {
    background: var(--light-blue-color);
    overflow: hidden;
    width: 100%;    
    padding-bottom: 15rem;
    padding-inline: 1rem;
}

.battleInputHeader, 
.playButton {
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif !important;
    -webkit-font-smoothing: antialiased; 
    -webkit-tap-highlight-color: transparent;
}

.betCard{ 
    background: var(--main-bg); 
    border-radius: 0.3rem;
    color: #ffffff;
    -webkit-animation: linear; 
    -webkit-animation-name: leftToRight; 
    -webkit-animation-duration: .3s; 
    border-radius: 0.3rem;
    animation-timing-function: linear; 
    animation-delay: 0s; 
    animation-iteration-count: 1; 
    animation-direction: normal; 
    animation-fill-mode: none; 
    animation-play-state: running; 
    animation-name: leftToRight; 
    animation-duration: 0.3s;
    box-shadow: rgba(2, 2, 2, 0.9) 0px 10px 10px -10px;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
} 

.betTitleDivBtn{   
    background: var(--light-blue-color);
    /* border:1px solid black; */
    margin-left: 1rem !important; 
    border: none;
    font-size: 0.75rem !important;
    padding: 0.3rem 0.8rem !important;
    border-radius: 0.25rem;
}


.battleInputHeader {
    /* border:1px solid black; */
    color: var(--dark-text-color);
    font-family: 'Poppins', sans-serif !important; 
    font-size: 1.5rem;
    padding: 1rem 0;
    /* margin-bottom: -5px; */
    /* margin-top: 5px; */
}

.betBodyDiv{
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-inline: 1rem;
    justify-content: space-between;

}


.amountsetBtn{
    background: var(--main-bg);
    padding: 0.3rem 0.8rem !important;
    color: #ffffff;
    border: 0;
    outline: 0;
    font-family: 'Poppins', sans-serif;
    border-radius: 0.3rem;
}

.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}

.formControl {
    display: block;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    /* background-color: #fff; */
    background: var(--main-bg);
    background-clip: padding-box;
    border-radius: .3rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: none;
}

.betcardbtn{
    border:1px solid white;
    margin-inline: auto;
    text-align: center;
    color: #ffffff;
    padding: 0.3rem 1.2rem;
    border-radius: 0.3rem;
}

.betcard_user_info{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ffffff;
    align-items: center;
}

.betCard_user_info{
    font-family: 'Poppins', sans-serif !important;
    /* color: #ffffff;  */
    color: #ffffff;
    font-size: 1em; 
    font-weight: 600; 
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.3rem;    
}

.betcard_user_set
{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
}


.betcard_user_set{
    width: 100%;
    padding: 0.5rem 0;
    justify-content: center;
    gap: 1.5rem;
}

.flex_column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.play_btn,
.requested_btn,
.cancel_btn,
.opponent_start{
    border: 0;
    outline: 0;
    border-radius: 0.3rem;
    font-size: 0.875rem !important;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 500;
    box-shadow: rgba(2, 2, 2, 0.3) 0px 8px 10px 2px;
    width: 100px;
    text-align: center;
}

.play_btn{
    background: #ffe600;
    color: var(--dark-text-color);
}

.requested_btn,
.opponent_start{
    background: #fec637;
    color: var(--dark-text-color);
}


.start_btn,
.reject_btn{
    border:none;
    outline: none;
    font-size: 0.875rem !important;
    padding: 0.3rem 0.87rem;
    border-radius: 0.2rem;
    box-shadow: rgba(2, 2, 2, 0.3) 0px 8px 10px 2px;
}

.start_btn{
    margin-right: 0.5rem !important;
}
.cancel_btn,
.reject_btn{
    background: red;
    color: #ffffff;
}



.start_btn{
    background: var(--light-blue-color);
    color: var(--dark-text-color) !important;
    font-size: 0.875rem !important;
}

.playButton {
    border: 0;
    outline: 0;
    border-radius: 5px;
    font-size: .7em;
    height: 30px;
    padding: 0 22px;
    color: #fff;
}

.playButton { 
    background: var(--main-bg);
    border-radius: 5px; 
    font-size: 0.7em !important; 
    height: 34px; 
    padding: 0 22px; 
    color: #ffffff;
    font-size: 1rem !important;
    border-radius: 0.3rem;
    text-decoration: none;
    padding-top: 0px; 
    padding-right: 22px; 
    padding-bottom: 0px; 
    padding-left: 22px;
} 
.playButton { 
    text-transform: uppercase;
} 

.playButton:hover{
    color: white !important;
    text-decoration: none;
}

.gamesSectionTitle {
    color: var(--dark-text-color) !important;
    font-size: 1.2em !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
    margin-top: 2rem;
}

.gamesSectionHeadline {
    color: #959595;
    font-size: .75em;
    font-weight: 400;
    line-height: 18px;
}
.gamesSectionHeadline > img {
    width: 20px;
    margin-right: 3px;
}

#root { 
    display: flex !important;
} 

.py-3 { 
    padding-top: 1rem !important;
} 

.py-3 { 
    padding-bottom: 1rem !important;
} 

.px-4 { 
    padding-right: 1.5rem !important;
} 

.px-4 { 
    padding-left: 1.5rem !important;
} 

*,:after,:before { 
    box-sizing: border-box;
} 

:-webkit-scrollbar { 
    display: none;
} 

.mb-2 { 
    margin-bottom: 0.5rem !important;
} 

.mt-1 { 
    margin-top: 0.25rem !important;
} 

.betCardTitle { 
    font-family: 'Poppins', sans-serif !important;
    /* color: #ffffff;  */
    color: #ffffff;
    font-size: 1em; 
    font-weight: 600; 
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 1rem;    
} 

img { 
    border-style: none; 
    border-top-style: none; 
    border-right-style: none; 
    border-bottom-style: none; 
    border-left-style: none;
} 

img { 
    vertical-align: middle;
} 

img { 
    font-family: 'Poppins', sans-serif; 
    -webkit-font-smoothing: antialiased; 
    -webkit-tap-highlight-color: transparent;
} 

span { 
    font-family: Roboto; 
    -webkit-font-smoothing: antialiased; 
    -webkit-tap-highlight-color: transparent;
} 

.gamesSectionTitle { 
    display: inline-block;
    color: #ffffff; 
    font-size: 1em; 
    font-weight: 700;
    padding-left: 0.5rem;
} 


/* .gamesSectionHeadline > img  { 
    width: 20px; 
    margin-right: 3px;
}  */

button { 
    border-radius: 0; 
    border-top-left-radius: 0px; 
    border-top-right-radius: 0px; 
    border-bottom-right-radius: 0px; 
    border-bottom-left-radius: 0px;
} 

button { 
    margin: 0; 
    font-family: inherit; 
    font-size: inherit; 
    line-height: inherit; 
    margin-top: 0px; 
    margin-right: 0px; 
    margin-bottom: 0px; 
    margin-left: 0px;
} 

button { 
    overflow: visible; 
    overflow-x: visible; 
    overflow-y: visible;
} 

button { 
    text-transform: none;
} 

button { 
    -webkit-appearance: button; 
    appearance: button;
} 

.bgSecondary { 
    background-color: #fbff00;
    color: var(--white-text-color);
} 
.bgSecondary:hover{
    color: var(--dark-text-color) !important;
}

.bet_card_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border:5px solid red;
}



.cxy { 
    align-items: center; 
    display: flex; 
    justify-content: center;
} 



 button:not(:disabled) { 
    cursor: pointer;
} 

button.bg-secondary:hover { 
    background-color: rgb(84, 91, 98) !important;
} 

.entry_fee{
    border:1px solid #ffffff;
}

.betCardSubTitle { 
    display: inline-block;
    color: #ffffff; 
    font-size: 1rem; 
    font-weight: 500;  
    text-transform: uppercase;
    /* border:1px solid black; */
    margin-top: 0.3rem;
} 


.betCardSubTitle2 { 
    display: inline-block;
    margin-left: 2rem;
    color: #ffffff; 
    font-size: 1rem; 
    font-weight: 500;  
    text-transform: uppercase;
    margin-top: 0.3rem;
} 

.betCardSubTitle2 img{
    margin-left: 1rem !important;
}

.betCardAmount,
.betCardAmount2 { 
    padding-left: 2px; 
    font-size: 1.2rem; 
    text-transform: uppercase;
} 


@keyframes leftToRight { 
  0% {  
      left: -500px; 
      left: -500px; 
  }  
  100% {  
      left: 0; 
      left: 0px; 
  }  

} 


:-webkit-scrollbar { 
    display: none;
} 

.betCard-title { 
    border-bottom: 1px solid #f5e3ff; 
    color: rgb(44, 44, 44); 
    font-size: 0.65em; 
    font-weight: 700; 
    height: 30px; 
    width: 100%; 
    border-bottom-width: 1px; 
    border-bottom-style: solid; 
    border-bottom-color: rgb(245, 227, 255);
} 

.betCard_playerName { 
    font-size: 1em !important; 
    font-weight: 500; 
    line-height: 1px;
} 

/*  running battles   end   */


.betCard_playerName {
    font-size: .7em;
    font-weight: 500;
    line-height: 1px;
  }


  .battle_delete_button{
    outline: none !important;
    border: none !important;
    padding-left: 2rem !important;
    background-color: #ffee00;
  }