
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgba(8,36,66,1) 0%, rgba(8,36,66,1) 47%, rgba(4,24,45,1) 100%);
    --light-blue-color: #d0e1f9;
    --dark-blue-color: #2a4d69;
    --dark-text-color: #071627;
}


.kyc-input{
background: #fafafa;
border: 1px solid #e0e0e0;
border-radius: 5px;
color: #2c2c2c;
font-family: 'Poppins', sans-serif;
font-size: 1em;
font-weight: 500;
height: 48px;
position: relative;
text-transform: uppercase;
display: flex;
align-items: center;
padding-left: 15px;
width: 100%;
}

.refer-footer{
background: #ffffff;
border-radius: 5px;
color: #2c2c2c;
font-family: 'Poppins', sans-serif;
font-size: 1em;
font-weight: 500;
height: 48px;
position: relative;
text-transform: uppercase;
display: flex;
align-items: center;
padding-left: 15px;
margin-bottom: 5rem;
margin-top: 3rem;

}
/* 
.refer-button {
    border: none;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: 700;
    height: 48px;
    padding: 0 22px;
    color: #fff;
    background: #ffffff !important;
    text-transform: uppercase;
} */
.kyc-select {
    bottom: 0;
    left: 0;
    max-width: 480px;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 4;
}

.kyc-select-enter-done{
display: block !important;
-webkit-transform: translateZ(0)!important;
transform: translateZ(0)!important;
}


.kyc-select .box {
    display: none;
    border-radius: 16px 16px 0 0;
    box-shadow: 0 -3px 8px 0 rgb(0 0 0 / 12%);
    max-height: 88%;
    overflow-y: scroll;
    pointer-events: auto;
    width: 100%;
    transition: transform .2s cubic-bezier(0,0,.3,1) 0s;
    /* will-change: transform;
    transition: -webkit-transform .2s cubic-bezier(0,0,.3,1) 0s;
    -webkit-transform: translate3d(0,360px,0);
    transform: translate3d(0,360px,0); */
}

.kyc-select .header {
    border-bottom: 1px solid #e0e0e0;
    z-index: 3;
    border-radius: 10px 10px 0 0;
    position: fixed;
    max-width: 480px;
    width: 100%;
    padding: 30px 20px;
}

.kyc-select .option {
    font-family: 'Poppins', sans-serif;
    border-bottom: 1px solid #e0e0e0 !important;
    color: #959595;
    font-size: 1em;
    font-weight: 500;
    height: 57px;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
    padding-left: 20px;
}

a{
    color: #000000;
    text-decoration: none;
}
a:hover{
    color: #000000;
    text-decoration: none !important;

}

.kyc-doc-input{
    border-bottom: 1px solid #ffffff;
    position: relative;
    width: 100%;
}
.kyc-doc-input input {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: -2px;
    padding-left: 0;
    text-transform: uppercase;
    width: 100%;
    color: #ffffff;
    border-bottom: 1px solid #ffffff !important;
}

.kyc-doc-input .label {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    padding: 0.5rem 0 ;
    font-weight: 400;
    position: relative;
    color: --bg;
    transition: all .3s;
}
.kyc-doc-input input[type="text"]{
    color: #ffffff;
}
.kyc-doc-input input[type=date]{
    color: #ffffff;
}
.kyc-doc-input input::placeholder{
    color: #ffffff;
}
.kyc-doc-input ::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

/* kyc3 */

.mytext {
    color: #2c2c2c;
    font-size: .95em !important;
    font-weight: 400;
    line-height: 21px;
    width: 100%;
}

.doc-upload {
    align-items: center;
    background-color: #fafafa;
    border-radius: 5px;
    border: 1px dashed #e0e0e0;
    display: flex;
    height: 70px;
    padding: 0.5rem !important;
    justify-content: center;
    position: relative;
    width: 100%;
}

.doc-upload input {
    height: 100%;
    opacity: 0;
    width: 100%;
    z-index: 2;
}

.kyc-select .overlay {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    background-color: #2c2c2c;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transition: opacity .2s;
}

.kyc-select .box {
    display: none;
    border-radius: 16px 16px 0 0 !important;
    box-shadow: 0 -3px 8px 0 rgb(0 0 0 / 12%);
    max-height: 88%;
    overflow-y: scroll !important;
    pointer-events: auto !important;
    width: 100%;
    will-change: transform;
    transition: -webkit-transform .2s cubic-bezier(0,0,.3,1) 0s;
    transition: transform .2s cubic-bezier(0,0,.3,1) 0s;
    transition: transform .2s cubic-bezier(0,0,.3,1) 0s,-webkit-transform .2s cubic-bezier(0,0,.3,1) 0s;
    -webkit-transform: translate3d(0,360px,0);
    transform: translate3d(0,360px,0);
}

.kyc-select .header {
    border-bottom: 1px solid #e0e0e0;
    z-index: 3;
    border-radius: 10px 10px 0 0;
    position: fixed;
    max-width: 480px;
    width: 100%;
    padding: 30px 20px;
}

.kyc-doc-input {
    border-bottom: 1px solid #ffcc5b;
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
}

.kyc-doc-input input {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: -2px;
    padding-left: 0;
    text-transform: uppercase;
    width: 100%;
}