@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgba(8,36,66,1) 0%, rgba(8,36,66,1) 47%, rgba(4,24,45,1) 100%);
    --light-blue-color: #d0e1f9;
    --dark-blue-color: #2a4d69;
    --dark-text-color: #071627;
}


:root{
    --bg-back: linear-gradient(90deg, rgba(0,0,81,1) 0%, rgba(25,91,130,1) 100%);
}

.main-area{
    background: var(--light-blue-color);
    padding-top: 1rem;
    padding-inline: 1rem;
    padding-bottom: 10rem;
}
.battleCard-bg {
    /* border:1px solid red; */
    min-height: 100vh;
    width: 100%;

}
.battleCard {
    border-radius: 10px;
}
.battleCard .players {
    background: var(--bg-blue-light);
    border:1px solid #ffffff;
    color: #959595;
    border-radius: 0.3rem;
    font-size: .75em;
    font-weight: 700;
    padding: 0.5rem 0;
}
.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}
.pt-2, .py-2 {
    padding-top: 0.5rem!important;
}
.battleCard .players {
    background: var(--bg-back);
    color: #959595;
    font-size: .75em;
    font-weight: 700;
    color: #ffffff;
    padding: 1rem 0;
}
img, svg {
    vertical-align: middle;
}
img {
    border-style: none;
}
*, :after, :before {
    box-sizing: border-box;
}
.ml-3, .mx-3 {
    margin-left: 1rem!important;
}
.mr-3, .mx-3 {
    margin-right: 1rem!important;
}
.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}
.flex-column {
    flex-direction: column!important;
}
.battleCard .amount {
    padding: 0.5rem;
    background: var(--bg-back);
    font-size: 1em;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 0.3rem;

}
.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}

.ludomanual_container{
    background: #fec637;
    text-align: center;
    padding: 0.875rem 0;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: #000051;
}

.mt-2, .my-2 {
    margin-top: 0.5rem!important;
}
.thin-divider-x {
    background-color: #e0e0e0;
    bottom: 0;
    height: 1px;
    left: 57px;
    right: 0;
    display: none;
}
.battleCard .roomCode {
    margin-top: 2rem;
    border-left: 3px solid #fec637;
    border-right: 3px solid #fec637;
    padding: 1rem;
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: center;
    font-weight: 500;
    color: #fec637;
    font-family: 'Poppins', sans-serif;

}

.battleCard .roomCode h6{
    font-size: 1.2rem !important;
    line-height: 1.5rem;
    padding-top: 0.5rem;
    font-weight: 500;
}

.battleCard .roomCode input{
    border:1px solid #fec637;
    background: transparent;
    margin: 1rem 0;
    border-radius: 0.3rem;
    color: #fdfdff;
}

.roomcode_btn{
    display: block;
    margin-top: 0.5rem;
    border: none;
    outline: none;
    font-size: 1rem !important;
    padding: 0.3rem 1.5rem;
    background: var(--bg-back);
    color: #ffffff;
    border-radius: 0.3rem;
}

.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}
.flex-column {
    flex-direction: column!important;
}
.battleCard .roomCode span {
    font-size: 1.5em;
    font-weight: 700;
    color: #000051;
}
.battle-input-header, .playButton {
    font-weight: 700;
    text-transform: uppercase;
}
.playButton {
    border: none;
    border-radius: 5px;
    font-size: .7em;
    height: 30px;
    padding: 0 22px;
    color: #fff;
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.bg-green {
    background-color: #0db25b;
}
.mt-2, .my-2 {
    margin-top: 0.5rem!important;
}
.position-static {
    position: static!important;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}
button, select {
    text-transform: none;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button {
    border-radius: 0;
}
.battleCard .app-discription {
    font-size: 1em;
    font-weight: 800;
}
.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.thin-divider-x {
    background-color: #e0e0e0;
    bottom: 0;
    height: 1px;
    left: 57px;
    right: 0;
}
.battleCard .rules {
    border-radius: 10px;
    margin-top: 2rem;
    font-size: 1em;
    font-weight: 500;
}

.battleCard .rules .rules-title{
    font-size: 1.2rem;
    text-decoration: none;
    color: #ffffff;
    background: var(--bg-back);
    padding: 0.5rem 0;
}

.rules_list_group_items{
    font-size: 1rem !important;
    text-align: center !important;
    text-transform: uppercase;
    font-weight: bold;
    color: #fec637;
    padding: 0.3rem 0;
    line-height: 1.5rem;
    
}


.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}
.battleCard .rules ol {
    font-size: .7em;
}
.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
    list-style-type: none;
}
.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}
.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    font-weight: 800;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
}
.list-group-item+.list-group-item {
    border-top-width: 0;
}
.ml-1, .mx-1 {
    margin-left: 0.25rem!important;
}
.mr-1, .mx-1 {
    margin-right: 0.25rem!important;
}
img, svg {
    vertical-align: middle;
}
img {
    border-style: none;
}
*, :after, :before {
    box-sizing: border-box;
}
.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}
.battleCard .match-status-border {
    background: var(--bg-back);
    padding: 10px 20px;
    font-size: .9em;
    font-weight: 600;
    margin-top: 1rem;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.battleCard .result-area {
    padding: 1rem 0.5rem;
}
.battleCard .result-area p {
    color: #fec637;
    font-size: .8em;
    font-weight: 500;
}

.result_submit_btn{
    background: var(--bg-back);
    border: none;
    outline: none;
    color: #ffffff;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.battleCard .result-area .radios {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.battleCard .result-area label {
    font-size: .8em;
    font-weight: 600;
    text-transform: uppercase;
}
.MuiFormControlLabel-root.Mui-disabled {
    cursor: default;
}
.MuiFormControlLabel-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    /* margin-left: -11px; */
    /* margin-right: 16px; */
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
.hidden {
    display: none!important;
}
.jss8 {
    color: #66bb6a;
}
.MuiRadio-root {
    color: rgba(0, 0, 0, 0.54);
}
.jss1 {
    padding: 9px;
}
.MuiIconButton-root {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}
.MuiButtonBase-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}
.MuiTypography-body1 {
    font-size: 1.2rem !important;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    letter-spacing: 0.00938em;
    padding-bottom: 0 !important;
}
.MuiTypography-root {
    margin: 0 !important;
}
.battleCard .result-area label {
    font-size: .8em;
    font-weight: 600;
    text-transform: uppercase;
}
.MuiFormControlLabel-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    /* margin-left: -11px; */
    /* margin-right: 16px; */
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.battleCard .result-area button {
    font-size: .9em;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 30px;
}
.jss4 {
    top: 0;
    left: 0;
    /* width: 100%; */
    cursor: inherit;
    /* height: 100%; */
    /* margin: 0; */
    /* opacity: 0; */
    padding: 0;
    z-index: 1;
    /* position: absolute; */
}
.jss5 {
    display: flex;
    position: relative;
}
.battleCard .app-discription {
    font-size: .8em;
    font-weight: 600;
}
.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}
.flex-column {
    flex-direction: column!important;
}
body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}
#post{
    font-weight: 500 !important;
}