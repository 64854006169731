@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgba(8,36,66,1) 0%, rgba(8,36,66,1) 47%, rgba(4,24,45,1) 100%);
    --light-blue-color: #d0e1f9;
    --dark-blue-color: #2a4d69;
    --dark-text-color: #071627;
}


.input_box{
    background: #FFF !important;
    display: flex;
    width: '85%';
    height: '60px';
    border-radius: '5px'; 
    margin-top: "10px";
    color: var(--dark-text-color);
}

.form-control{
    color: var(--dark-text-color) !important;
    background: transparent !important;
    background-color: transparent !important;
    border: none;
    outline: none;
    font-family: 'Poppins', sans-serif !important;
    font-size: 1.2rem !important;
}

.form-control:focus{
    color: var(--dark-text-color) !important;
    background-color: transparent !important;
    border: none;
    outline: none;
    font-family: 'Poppeins', sans-serif !important;
    font-size: 1.2rem !important;
}   
.form-control::placeholder{
    font-family: 'poppins', sans-serif;
    font-weight: 200;
    color: var(--dark-text-color) !important;
    border: 0;
    background: transparent !important;
    background-color: transparent !important;
    font-size: 1.2rem !important;
}
.splash-overlay {
    /* background: linear-gradient(rgba(0, 0, 0, 0.61),rgba(0, 0, 0, 0.596)); */
    background: linear-gradient(180deg, transparent -0px, #000 600.5px);
    background-size: cover !important;
    background-position: center !important;
    height: 100%;
    pointer-events: none;
    position: absolute;
    width: 100%;
    max-width: 480px;
    z-index: 2;
}
/*.w3-sidebar > a > div{*/
/*    font-family: "Luckiest Guy", cursive;*/
/*    font-size: small;*/
/*    letter-spacing: 2px;*/
/*}*/
.splash-screen {
    -webkit-animation: splashAnim 22s linear infinite;
    animation: splashAnim 22s linear infinite;
    height: 100%;
}

.center-xy {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.login-footer {
    font-family: 'Poppins', sans-serif;
    padding: 3rem 1rem 0;
    color: #ffffff;
    font-size: .75em;
    font-weight: 200;
    line-height: 1.2rem;
    position: sticky;
    text-align: center;
    max-width: 480px;
    width: 100%;
    z-index: 2;
    position: absolute;
    top: 22rem;
}

element.style {
    top: 45%;
    z-index: 3;
}

.splash-screen {
    -webkit-animation: splashAnim 22s linear infinite;
    animation: splashAnim 22s linear infinite;
    height: 100%;
}

figure {
    margin: 0 0 1rem;
}

.font-15 {
    color: #2c2c2c;
    font-size: 1.5em;
    font-weight: 700;
}

.text-white {
    color: #fff !important;
}

.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}

.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}

element.style {
    width: 85%;
    height: 70px;
    border-radius: 5px;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

element.style {
    width: 100px;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.hidden {
    display: none !important;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.cxy, .refer-button-copy {
    align-items: center;
    display: flex;
    justify-content: center;
}

.refer-button {
    border: none;
    border-radius: 5px;
    font-size: 1em;
    font-weight: 700;
    height: 48px;
    padding: 0 22px;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
}

.refer-button:hover{
    color: white !important;
    text-decoration: none;
}

.bg-green {
    background-color: #0db25b;
}

.mt-4, .my-4 {
    margin-top: 1.5rem !important;
}
/* input[name="mobile"] {
   border: 1px solid #d8d6de;
  }
input[name="mobile"]:focus {
   border-color: #7367f0;
  }
 */

  
    .form_title{
        font-family: 'Poppins', sans-serif !important;
        font-size: 1.2rem;
        

    }

  .Login-button{
        border: none;
        border-radius: 0.3rem;
        font-family: 'Poppins', sans-serif !important;
        font-size: 1.2rem;
        font-weight: normal !important;
        height: 48px;
        padding: 0 22px;
        color: #fff;
        /* border: 3px solid #f4bc41; */
        /* box-shadow: rgb(244 188 65) 0px 0px 1rem; */
        /* font-family: "Luckiest Guy", cursive !important; */
        /* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)); */
        background: var(--main-bg);
        /* width: 85%; */
        width: 100%;
        font-weight: 900;
        /* letter-spacing: 1px; */
        text-transform: uppercase;
    }
.splash-screen figure img{
        filter: blur(1px);
    } 

.input_checked_box{
    margin-top: 1rem;
    position: sticky;
    text-align: left;
    color: #ffffff;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
}

.form_box{
    margin: auto;
    width: 85%;
    /* background: rgba( 255, 255, 255, 0.40 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 ); */
}
input::-webkit-input-placeholder {
    color: #FFF;
}

input:-moz-placeholder {
   color: #FFF;
}