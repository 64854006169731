
:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgba(8,36,66,1) 0%, rgba(8,36,66,1) 47%, rgba(4,24,45,1) 100%);
    --light-blue-color: #d0e1f9;
    --dark-blue-color: #2a4d69;
    --dark-text-color: #071627;
}



.headerContainer {
    background: var(--main-bg); 
    max-width: 480px !important;   
    width: 100%;
    /* position: fixed;
    top: 100; */
    /* height: 60px; */
    padding: 0.5rem 0.6rem;

    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;
    box-shadow:0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.flex_header{
    display: flex;
    flex-direction: row;
    justify-self: space-between;
    align-items: center;
}

.sideNavIcon {
    overflow: hidden;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    border:1px solid red;
}



.sideNavIcon>img {
    height: 14px;
    width: 16.5px;
    margin-bottom: 14px;
  
}


.navLogo_logo2{
    margin-left: 0.5rem;
    /* width: 300px !important; */
    margin-right: 0 !important;
}

.navLogo .img-fluid{
    height: 300px !important;
}
.menu_items {
    height: auto;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-end;
    padding-right: 0.3rem;

}

.menu_items>.box {

    align-items: center;
    color: var(--dark-text-color);
    display: flex;
    text-align: center;
    background-color: var(--light-blue-color);
    padding: 0.4rem 0.2rem;
    border-radius: 0.3rem;
}

.moneyBox_header {
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 600;
    text-align: center;
}


.moneyBox_text {
    color: var(--blue-dark-color);
    font-size: 1rem;
    font-weight: 900;
    /* position: relative;
    bottom: 4px; */
}


.moneyIcon_container {
    
    overflow: hidden;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
}

.moneyBox_add {
  
    background: var(--bg-back);
    /* background-color: #ededed; */
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    color: #ffffff !important;
   

}
.referBox_add {
   
    bottom: 0px;
    position: relative;
    right: 0px;
    top: 0px;
    /*height: 95%;*/
    width: 25px;
    border-radius: 50%;
    border: 2px solid #f4bc41;
    color: #f4bc41;
    margin-left: 5px;
    box-shadow: rgb(244 188 65) 0px 0px 0.2rem;
    text-align: center;

}

.moneyIcon_container>img {
    width: 25px;
}



.login_btn {
    color: blue;
    font-size: 1em;
    font-weight: 900;
    position: relative;
    bottom: 0px;
    /* padding-left: 151px; */
}

.walletAdd>img {
    /* height: 9px; */
    width: 14px;
    margin-left: 6px;
    
}

.buttoncolor{
    background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important;
    color: black !important;
    border: 2px  solid #f4bc41 !important;
    border-radius: 14px;
    
}



