@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgba(8,36,66,1) 0%, rgba(8,36,66,1) 47%, rgba(4,24,45,1) 100%);
    --light-blue-color: #d0e1f9;
    --dark-blue-color: #2a4d69;
    --dark-text-color: #071627;
}
.profile_wallet { 
    /* height: 70px;  */
    padding: 0.5rem;
    mix-blend-mode: normal; 
    opacity: 1; 
    background: var(--main-bg); 
    border: 1px solid #e0e0e0; 
    border-radius: 0.3rem;
    color: #ffffff !important;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
 

} 
.my_text,
.my_text2{
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;
    color: rgb(191,211,255);
    margin-top: 1rem;
}
/* .divider_x { 
    background: #f1f1f1; 
    width: 105%; 
    height: 20px; 
    background-image: initial; 
    background-position-x: initial; 
    background-position-y: initial; 
    background-size: initial; 
    background-attachment: initial; 
    background-origin: initial; 
    background-clip: initial; 
    background-color: rgb(241, 241, 241);
}  */


.wallet_section{
    background: var(--light-blue-color);
    /* border:1px solid black; */
    padding: 1rem;
}

.wallet_card,
.wallet_card2 { 
    width: 47%;
    padding: 1rem;
    background: var(--main-bg);
    background-size: cover; 
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.875em;
    min-height: 250px !important;

}  



.walletCard_btn { 
    margin-top: 1rem !important;
       width: 120px; 
    font-size: 0.875rem !important; 
    font-weight: 700; 
    padding: 0.5rem;
    background: var(--light-blue-color);
    /* background-color: #ffffff; */
    color: var(--dark-text-color);
    border-radius: 3px;
    outline: none;
    border: none;
    text-transform: uppercase;

} 

.order_history{
    background-color: var(--main-bg);
}



