/* KD Ludo Stylesheet Sheet */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



:root{
    /* --main-bg: linear-gradient(90deg, rgba(20,32,46,1) 0%, rgba(20,32,46,1) 63%, rgba(7,13,19,1) 100%); */
    --main-bg: linear-gradient(90deg, rgba(8,36,66,1) 0%, rgba(8,36,66,1) 47%, rgba(4,24,45,1) 100%);
    --light-blue-color: #d0e1f9;
    --dark-blue-color: #2a4d69;
    --dark-text-color: #071627;
}
.row{
    margin-right: 0 !important;
    margin-left: 0 !important;
}
/* ========================== Home Dashboard Page ========================== */

/* {{{{  User Footer Bar }}}}   */

#userFooterBar {
    background: var(--main-bg);
    /* border: 1px solid black; */
    position:fixed;
    bottom: 0;
    left: 0;
    z-index: 99999;
    background-color: var( --light-blue-color);
    width: 100%;
    text-align: center;
    max-width: 480px;
    padding-bottom: 0.3rem !important;
}

.footer_list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border:1px solid yellow; */
    padding: 0.5rem 2rem 0;   
    /* border:1px solid #fff; */
    position: relative;
    bottom: 0;
    left: 0;
}

.profile_icon i{
    color: var( --light-blue-color);
}

.profile_icon_margin_left{
    margin-left: 5rem;
}

.profile_icon .title_name{
    color: var( --light-blue-color);
    font-size: 0.75rem !important;
    margin-bottom: 0 !important;
    /* font-weight: lighter; */
    font-weight: 300;
    font-family: var(--poppins-font);
}

.game_btn_main{
    width: 100%;
  
    position: absolute;
    left: 0;
    bottom: 0.5rem;
    height: 60px;
    display: flex;
    justify-content: center;

}
.profile_btn_box{
    width: 56px;
    height: 56px;
    border-radius: 100%;
    /* background-color: var( --light-blue-color); */
    background: var(--main-bg);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile_game_btn{
    outline: none;  
    border: none;
    background: transparent;
    background-color: transparent;
}

.profile_game_btn i{
    font-size: 2rem;  
    transform: rotate(45deg);
    color: yellow;

}

.icon_zindex{
    position: relative;
    z-index: 2;
}

/* {{{{  =====================  Download Top Bar  =====================    }}}}   */

.app_download_bar{
    max-width: 480px;
    background-color: #ffdb4f;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: space-between ;
    padding: 0.5rem;
}

/* .app_download_bar p{
    font-size: 1.3rem !important;
    margin-bottom: 0 !important;
    color: #ffdb4f;
    text-transform: uppercase;
    font-weight: bold;
} */

.app_download_btn{
    outline: none;
    border: none;
    border-radius: 0.2rem;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;    
}

.app_download_btn span{
    margin: 0;
    font-size: 0.9rem;
    line-height: 0.9rem;
    padding: 0.4rem;
    display: block;
    background: var(--white-bg);
    border-radius: 0.3rem;
    
}

.download_bar_logo{
    align-items: center;
    justify-content: center;
}

.app_download_close{
    display: flex;
    align-items: center;
    justify-content: center;

}

.app_download_close i{
    font-size: 1.4rem;
    color: var(--white-bg);
    margin-bottom: 0;
    padding-bottom: 0;
}

/* {{{{  ===================== Battle Page  =====================    }}}}   */

.input_header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}


.battle_input{
    /* border:1px solid yellow; */
}
.battle_input input:focus{
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
}
.battle_input input::placeholder{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
    color: #ffffff !important;
}
.battle_game_section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid black; */
    padding: 0 0.5rem;
}



/* {{{{  ===================== Profile Page  =====================    }}}}   */


.profile_container{
    padding: 0;
    margin:0;
    /* border:1px solid green;  */
}

.profile_box{
    background: var(--light-blue-color);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
    margin:0;
    /* border:1px solid blueviolet; */
    gap: 2rem;
    transition: all 0.3s ease-in-out;
}

.profile_sub_box{
    /* border:1px solid red; */
    width: 50%;
}


.profile_wallet,
.kyc_container,
.refer_box,
.won_cash_box,
.battle_played_box,
.refer_earning_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center ;
    background: var(--main-bg); 
    border-radius: 0.5rem;
    padding: 0 0.5rem !important;
    min-height: 150px;
}


.profile_wallet i,
.kyc_container i,
.refer_box i,
.won_cash_box i,
.battle_played_box i,
.refer_earning_box i{
    color: #ffffff;
    font-size: 2rem;
}

.profile_wallet_text,
.kyc_text,
.refer_code,
.cash_won,
.battle_played_text,
.referal_earning_text{
    font-family: 'Poppins', sans-serif;
    color: #ffffff !important;
    text-align: center;
    padding: 0.5rem 0;
    font-size: 1rem;
    
}

.profile_title{
    margin: auto;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000000;
    text-align: center;
    padding: 1rem 0;
    font-size: 1.2rem;
    background: var(  --light-blue-color);
}

.profile_wallet picture{
   max-height: 20px;
}


.profile_wallet:hover,
.kyc_container:hover,
.refer_box:hover,
.won_cash_box:hover,
.battle_played_box:hover,
.refer_earning_box:hover{
    transform: scale(110%);
}

.logout_container{
    background: var(--main-bg);
    padding-top: 1rem;
    padding-bottom: 4rem;
    font-family: 'Poppins', sans-serif;
    color: #ffffff !important;
}

/* {{{{  ===================== KYC Page  =====================    }}}}   */

.kyc_page_2{
    background: var(--main-bg);
    color: #ffffff;
    text-align: justify;
    font-family: 'Poppins', sans-serif !important;
}
.kyc_page_2_title{
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-size: 1.4rem !important;
    padding: 1rem 0;
}
.kyc-doc-input label{
    font-size: 1.2rem !important;
    padding: 0.5rem 0;
}
.aadhar_title p{
    text-align: center;
    font-size: 1.3rem !important;
    margin-bottom: 0 !important;
    padding: 0.5rem 0;
}

.id_uploader{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem; 
}



/* {{{{  ===================== Refer & Earn Page  =====================    }}}}   */

.earn_now_title{
    padding: 0.5rem 2rem;
    font-family: 'Poppins', sans-serif;
    background: var(  --light-blue-color);
    color: #fec637 !important;    
    border-radius: 0.3rem;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
}
.earn_now_title span{
    padding-left: 0.5rem;
}
.earn_now_caption{
    font-size: 1rem;
    padding-top: 0.5rem;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
}

.earned_box_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.current_earning,
.total_earning{
    min-width: 45%;
    background: var(  --light-blue-color);
    padding: 0.5rem;
    color: #fec637;
    font-family: 'Poppins', sans-serif;
    border-radius: 0.3rem;
    min-height: 110px;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid;
}

.current_earning b,
.total_earning b{
    font-family: 'Poppins', sans-serif;
    padding-left: 0.5rem;
    font-size: 1.2rem;
}

.earning_redeem{
    background: var(--main-bg);
    color: #ffffff;
    font-size: 0.875rem;
    border-radius: 0.3rem;
    padding: 0.3rem 0.8rem;
    margin-top: 0.5rem;
}

.earning_redeem:hover{
    color: #ffffff !important;
    filter: opacity(0.7);
}
.refer_code,
.total_refers{
    font-size: 1rem;
    font-family: var(--poppins-font);
}

.refer_rules_container{ 
    padding-top: 2rem;
    padding-inline: 2rem;
    padding-bottom: 8rem;
    background: var(--main-bg)  ;
}

.refer_earn_rules_title{
    font-size: 1.25rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #ffffff;
    width: 80%;
    margin: auto;
    border-radius: 0.3rem;
}

.refer_earn_content{
    font-family: 'Poppins', sans-serif;
    font-size: 0.875em;
    margin-inline: auto;
    text-align: center; 
    color: #ffffff;
    font-weight: 200;
    margin-bottom: 1rem;
}

.referral_content_img{
   display: block;
   text-align: center;
}

.referral_wining_content{
    margin-bottom: 1rem;
    color: #ffffff;
    text-align: center;

}
.referral_notification{
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #ffffff;
}


.refer_footer_bar{
    border:1px solid black;
}




/* {{{{  ===================== Terms & Conditions Page  =====================    }}}}   */


.terms_conditions_container{
    background: var(--main-bg) !important;
    color: #ffffff;
    padding-bottom: 5rem;
}

.terms_condition_title{
    background-color: var(--light-blue-color);
    padding: 0.5rem 1rem;
    color: #ffdb4f;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    text-align: center;
    border-radius: 0.3rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 2rem 0;
}
.term_sub_title{
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem !important;
    padding: 1rem 0;
}
.term_content{
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    font-weight: 300;
    text-align: justify;
}

.child_title{
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem !important;
    padding: 1rem 0;
    text-align: justify;
}


.child_term li{
    font-family: 'Poppins', sans-serif;
    text-align: justify;
    padding: 1rem 0;
}

/* {{{{  ===================== Support Page  =====================    }}}}   */


.support_container{
    background: var(--main-bg) !important;
    min-height: 100% !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
}

.support_icon_container{
    display: flex;
    gap: 1.2rem;
}

.kdsupport_information{
    margin-top: 2rem;
}



/* {{{{  ===================== Redeem CSS  =====================    }}}}   */

.redeem_main_section{
    /* border:1px solid red; */
    width: 100%;
    height: 100vh;
    background: var(--main-bg);
    padding: 1rem;
}
.games_section_redeem{
    /* border:1px solid black; */
}

.redeem_title{
    font-size: 1.3rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    background: var(--main-bg);
    border-radius: 0.3rem;
    padding: 0.5rem;
    color: #ffffff;
    margin-bottom: 0.3rem;
}

.game_section_headline{
    font-size: 0.875rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-weight: 200;
}


.redeem_input_form{
    margin-top: 2rem;
}
.redeem_input{
    width: 100%;
    background: var(  --light-blue-color);
    height: 40px;
    outline: none;
    border: none;
    border-radius: 0.3rem;
    color: #ffdb4f;
    padding-left: 1rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

.redeem_input::placeholder{
    color: #ffdb4f;
}

.redeem_caption{
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    padding-top: 0.5rem;
    display: block;
    font-weight: 200 !important;
}

.redeem_info{
    color: #ffffff;
    padding-top: 2rem;
    font-size: 0.875rem !important;
    text-align: center;
    font-weight: 200;
    font-family: 'Poppins', sans-serif;
}
.redeem_btn_container{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.redeem_btn{
    padding: 0.5rem 2rem;
    background: var(  --light-blue-color);
    border-radius: 0.3rem;
    border: none;
    outline: none;
    font-family: 'poppins', sans-serif;
    font-weight: 500;
    color: #ffdb4f;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


/* {{{{  ===================== Transcation Page  =====================    }}}}   */

.transaction_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--main-bg);
    margin: 1rem;
    padding: 1rem 0;
    border-radius: 0.3rem;
}

.transaction_subcontainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.transcation_header{
    background: var(  --light-blue-color) !important;
}

.refer_code_section{
    padding: 2rem 2rem; 
    background: var(--main-bg);
}


.refferal_code{
    background: var(  --light-blue-color);
    padding: 1rem 0;
    border-radius: 0.3rem;
    color:#ffdb4f;
    margin-bottom: 0.5rem;
}

.refferal_code i{
    color :#ffdb4f;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.refer_code_section .total_refers{
    color: #ffffff;
}

.refer_whatsapp_btn{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.refer_whatsapp_btn a button{
    padding: 0.4rem 1.2rem;
    background-color: rgb(37,211,102);
    outline: none;
    border: none;
    color: #ffffff;
    border-radius: 0.3rem;
}


/* {{{{  ===================== Transcation Page  =====================    }}}}   */

.withdrawl_section{
    background: var(--main-bg);
    padding: 1rem;
}

.first_bank_transfer_btn{
 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-inline: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.bank_transfer_container{
    min-width: 100%;
    display: flex;
    align-items: center;
}

.transfer_form_section{
    width: 100%;
}

.withdrawl_option{
    font-size: 1.2rem !important;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.bank_title{
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #100068;
}

.bank_title_caption{
    font-size: 0.875rem;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    color: #100068;
}

.transfer_btn_container{
    width: 100%;
    margin-bottom: 2rem;
}

.transfer_back_btn{
    border: none;
    background-color: #ffffff;
    color: #100068;
    font-weight: bold;
    padding: 0.3rem 1.2rem;
    border-radius: 0.3rem;
}

.account_details{
    margin-top: 1rem;
    /* border: 1px solid yellow; */
        text-align: left;
}

.bank_account_title{
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    
}

.bank_account_title i{
    margin-right: 0.5rem;
}

.bank_account_form{
    border:1px solid #ffffff !important;
    min-width: 100%;
    height: calc(2.25rem + 2px);
    font-weight: normal;
    font-size: 0.875rem;
    padding: 0.5rem;
    border-radius: 0.3rem;
    color: #100068;
}
.withdrawal_btn_container{
    margin-top: 2rem;

}
.withdrawl_btn{
    margin-top: 2rem;
    background-color: #55da60;
    border: none;
    padding: 0.5rem 1.5rem;
    color: #ffffff;
    border-radius: 0.3rem;
    text-transform: uppercase;
}

/* {{{{  ===================== Battle Page  =====================    }}}}   */

.battleCard_container{
    border:1px solid red;
}



.download_bar_logo img {
    margin-right: auto;
}

.footer_list a {
    color: #FFF;
    /* border: 2px solid; */
}

.Nav_box__Ozua7 {
    border: 2px solid #f5cb53;
    height: 36px;
    margin-top: 8px;
    background: #FFF !important;
}

#userFooterBar {
    border-top: 2px solid;
    border-top: 2px solid #ffdb4f;
    /* border-radius: 1rem; */
    box-shadow: 0 10px 10px -10px #fade72;
}

body {
    background: #421808;
}

.profile_sub_box {
    border: 1px solid #E4A740 !important;
    border-radius: 10px !important;
}


.profile_title {
    color: #FFF;
}

.logout_container a {
    background: #d6942b;
    padding: 10px;
    border-radius: 10px;
    color:#FFF
}


input.form-control::-webkit-input-placeholder {
        color: #FFF;
}

input.form-control:-moz-placeholder {
       color: #FFF;
}
.snip-img.img-fluid.navLogo_logo2 {
	width: 80px;
}


.w3-teal .justify-content-between {
    border-bottom: 1px solid #FFDB4F;
}

.battle_input input {
    border: 1px solid #efd644 !important;
}
.profile_btn_box {
    border-top: 2px solid #FFDB4F;
}

.earning_redeem {
    background: #FEC637 !important;
      background-color: #FEC637 !important;
}


.refferal_code {
    border: 1px solid !important;
}
.refferal_code i {
	color: #fec637;
}
.walletB button{
    border: 1px solid #fec637 !important;
    color: #fec637;
}
.order_history a{
    background: #fec637;
    color: #000 !important;
    font-weight: bold;
}
.createBtl {
	color: #fff;
}
.input_header .set button{
    color: #000;
    background: #fec637;
}

.opnBtl{
    color: #fec637 !important;
}

.betMy{
    border: 1px solid #fec637;
}
.strtBtn{
    background: #fec637;
}
.kyc_banner{
    color: #fec637;
}